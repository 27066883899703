import Vue from 'vue';

const DataService = {

	install(Vue/*, options*/) {

		let api = {

			common: {
				getSelectedFromString: function (options, str) {
					let selected = [];
					if (str) {
						str += '';
						let itemList = str.split(',').map(r => +r).filter(r => r);
						options.forEach(o => {
							if (itemList.indexOf(o.v) > -1) {
								selected.push(o);
							}
						});
					}
					return selected;
				}
			},

			general: {

				getIntegrations: async function (){
					if (!this.integrationTypeOptions) {
						try {
							let resp = await Vue.$api.get('advertisers/getIntegrations');
							this.integrationTypeOptions = resp.integrations;
						} catch (e) {
							console.error(e);
						}
					}
					return this.integrationTypeOptions;
				},

				campaignStatusOptions: [
					{v: 'draft', t: 'Draft'},
					{v: 'live', t: 'Live'},
					{v: 'paused', t: 'Paused'},
					{v: 'archived', t: 'Archived'},
				],
				mmpOptions: [
					{v: 'appsflyer', t: 'AppsFlyer'},
					{v: 'kochava', t: 'Kochava'},
					{v: 'adjust', t: 'Adjust'},
					{v: 'singular', t: 'Singular'},
					{v: 'branch', t: 'Branch'},
					{v: 'tenjin', t: 'Tenjin'},
					{v: 'tune', t: 'Tune'},
				],
				platformOptions: [
					{v: 'android', t: 'android'},
					{v: 'ios', t: 'ios'},
					{v: 'web', t: 'web'},
				],
				currencyOptions: [
					{v: 'USD', t: 'USD'},
					{v: '%', t: '%'},
				],

				timezoneOptions: [
					{v: -9, t: '-09:00'},
					{v: -8, t: '-08:00'},
					{v: -7, t: '-07:00'},
					{v: -6, t: '-06:00'},
					{v: -5, t: '-05:00'},
					{v: -4, t: '-04:00'},
					{v: -3, t: '-03:00'},
					{v: -2, t: '-02:00'},
					{v: -1, t: '-01:00'},
					{v: 0, t: '+00:00 UTC'},
					{v: 1, t: '+01:00'},
					{v: 2, t: '+02:00'},
					{v: 3, t: '+03:00'},
					{v: 4, t: '+04:00'},
					{v: 5, t: '+05:00'},
					{v: 6, t: '+06:00'},
					{v: 7, t: '+07:00'},
					{v: 8, t: '+08:00'},
					{v: 9, t: '+09:00'},
				],
				payoutModelOptions: [{v: 'CPA'}, {v: 'CPI'}, {v: 'CPR'}, {v: 'CPC'}, {v: 'CPM'}, {v: 'CPS'}],

			},

			country: {
				_options: [],
				_hash: {},
				async getOptions(reload) {
					if (reload || this._options.length === 0) {
						let resp = await Vue.$api.get('geo/getCountries', {params: {page_size: 10000}});
						this._options = resp.records;
					}
					return this._options;
				}
			},
			region: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if (reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getRegions', {
							params: {
								page_size: 10000,
								country
							}
						}) : await Vue.$api.get('geo/getRegions', {params: {page_size: 10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getRegions', {params: countries});
						return resp.records;

					} catch (e) {
						throw e
					}
				}
			},

			city: {
				_options: [],
				_hash: {},
				async getOptions(reload, country = null) {
					if (reload || this._options.length === 0) {
						let resp = country ? await Vue.$api.get('geo/getCities', {
							params: {
								page_size: 10000,
								country
							}
						}) : await Vue.$api.get('geo/getCities', {params: {page_size: 10000}});
						this._options = resp.records;
					}
					return this._options;
				},
				async getOptionsByCountry(countries) {
					try {
						let resp = await Vue.$api.get('geo/getCities', {params: countries});
						return resp.records;
					} catch (e) {
						throw e
					}
				}
			},


			admin: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						first_name: '',
						last_name: '',
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('admins/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('admins/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},


			advertiser: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						name: '',
						email: '',
						password: '',
						status: 0,
						integration_type: ''
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('advertisers/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('advertisers/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},


			appCampaign: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						name: '',
						advertiser_id: null,
						asset_id: null,
						status: 'draft',
						age_restricted: false,
						is_approved: false,
						payout_model: 'CPI',
						payout: 0,
						description: '',
						conversion_flow: '',
						click_url: '',
						impression_url: '',
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('app-campaigns/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('app-campaigns/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},


			user: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						first_name: '',
						last_name: '',
						birthdate: null,
						gender: null
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('users/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('users/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},
			asset: {
				_booleans: [],
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						name: '',
						package_id: '',
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('assets/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('assets/getOptions');
						this._options = resp.options;
					}
					return this._options;
				}
			},

			network: {
				_options: [],
				_hash: {},
				newInstance() {
					return {
						id: 0,
						name: '',
						slug: '',
						assigned_domains: [],
					};
				},
				async get(id, reload) {
					if (!this._hash[id] || reload) {
						let resp = await Vue.$api.get('networks/get/' + id);
						this._hash[id] = resp.entity;
					}
					return this._hash[id];
				},
				async getOptions(reload) {
					if (!this._options.length || reload) {
						let resp = await Vue.$api.get('networks/getOptions');
						this._options = resp.options;
					}
					return this._options;
				},
			},
		};


		Vue.$dwData = api;
		Vue.prototype.$dwData = api;
	}

};


Vue.use(DataService);

export default DataService;
