import Vue from 'vue';
import ApiService from "@/core/services/api.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";


function getToken(){
	return window.localStorage.getItem('authToken');
}

const state = {
	errors: null,
	user: {},
	isAuthenticated: !!getToken()
};

const getters = {
	currentUser(state){
		return state.user;
	},
	isAuthenticated(state){
		return state.isAuthenticated;
	}
};

const actions = {
	[LOGIN](context, credentials){
		return new Promise((resolve, reject) => {
			let loginPath = process.env.VUE_APP_API_BASE_URL + 'admin/auth/login';
			Vue.axios.post(loginPath, credentials)
				.then(({data}) => {
					// console.log('GOT DATA login', data);
					context.commit(SET_AUTH, data.data);
					resolve(data);
				})
				.catch(({response}) => {
					let err = response && response.data && response.data.errors ? response.data.errors : 'General error';
					if (response && response.status === 401){
						err = 'Invalid username/password';
					}
					context.commit(SET_ERROR, err);
					reject(err);
				});
		});
	},
	[LOGOUT](context){
		context.commit(PURGE_AUTH);
	},
	[VERIFY_AUTH](context){
		let token = getToken();
		if (token) {
			ApiService.setToken(token);
			let currentUserPath = process.env.VUE_APP_API_BASE_URL + 'admin/auth/currentUser';
			return Vue.axios.get(currentUserPath)
				.then(({data}) => {
					// console.log('GOT DATA currentUser', data);
					context.commit(SET_AUTH, data.data);
					return true;
				})
				.catch(({response}) => {
					context.commit(SET_ERROR, response && response.data ? response.data.errors : 'There has been an error');
					return false;
				});
		} else {
			context.commit(PURGE_AUTH);
			return false;
		}
	},
	[UPDATE_PASSWORD](context, payload){
		const password = payload;

		return ApiService.put("password", password).then(({data}) => {
			context.commit(SET_PASSWORD, data);
			return data;
		});
	}
};

const mutations = {
	[SET_ERROR](state, error){
		state.errors = error;
	},
	[SET_AUTH](state, data){
		state.isAuthenticated = true;
		state.user = data.user;
		state.errors = {};
		localStorage.setItem('user', data.user);
		if (data.token) {
			localStorage.setItem('authToken', data.token);
		}
	},
	[SET_PASSWORD](state, password){
		state.user.password = password;
	},
	[PURGE_AUTH](state){
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		localStorage.removeItem('user');
		localStorage.removeItem('authToken');
		// Vue.$router.push({name: 'login'});
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
