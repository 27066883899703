import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);

const withPrefix = (prefix, routes) =>
	routes.map((route) => {
		route.path = prefix + route.path;
		return route;
	});

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			redirect: '/app'
		},
		{
			path: '/app',
			component: () => import('@/views/layout/Layout'),
			children: [
				{
					path: '',
					name: 'dashboard',
					component: () => import('@/views/pages/Dashboard.vue')
				},
				{
					path: 'assets',
					name: 'assets',
					component: () => import('@/views/pages/assets/List.vue')
				},
				{
					path: 'reports',
					name: 'reports',
					component: () => import('@/views/pages/reports/Reports.vue')
				},

				...withPrefix('users', [
					{
						name: 'user-list', path: '',
						component: () => import('@/views/pages/users/List.vue')
					},
					{
						name: 'user-add', path: '/add',
						component: () => import('@/views/pages/users/Form.vue')
					},
					{
						name: 'user-edit', path: '/edit/:id',
						component: () => import('@/views/pages/users/Form.vue')
					}
				]),

				...withPrefix('admins', [
					{
						name: 'admin-list', path: '',
						component: () => import('@/views/pages/admins/List.vue')
					},
					{
						name: 'admin-add', path: '/add',
						component: () => import('@/views/pages/admins/Form.vue')
					},
					{
						name: 'admin-edit', path: '/edit/:id',
						component: () => import('@/views/pages/admins/Form.vue')
					}
				]),

				...withPrefix('advertisers', [
					{
						name: 'advertiser-list', path: '',
						component: () => import('@/views/pages/advertisers/List.vue')
					},
					{
						name: 'advertiser-add', path: '/add',
						component: () => import('@/views/pages/advertisers/Form.vue')
					},
					{
						name: 'advertiser-edit', path: '/edit/:id',
						component: () => import('@/views/pages/advertisers/Form.vue')
					}
				]),

				...withPrefix('app-campaigns', [
					{
						name: 'app-campaign-list', path: '',
						component: () => import('@/views/pages/app-campaigns/List.vue')
					},
					{
						name: 'app-campaign-add', path: '/add',
						component: () => import('@/views/pages/app-campaigns/Form.vue')
					},
					{
						name: 'app-campaign-edit', path: '/edit/:id',
						component: () => import('@/views/pages/app-campaigns/Form.vue')
					}
				]),
			]
		},
		{
			name: 'login',
			path: '/login',
			component: () => import("@/views/pages/Login")
		},
		// {
		// 	path: '*',
		// 	redirect: '/404'
		// },
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/views/pages/error/Error-1.vue")
		}
	]
});
